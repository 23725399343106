<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input class="filter-item" v-model="listQuery.order_no" placeholder="订单号" style="width: 220px;" clearable/>
            <!-- 店铺管理员隐藏该选型 -->
            <template v-if="user.user.type !== 'shop'">
                <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="所属店铺" style="width: 140px" clearable>
                    <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
                    </el-option>
                </el-select>
            </template>
            <el-select class="filter-item" v-model="listQuery.satisfied_status" placeholder="满意程度" style="width: 140px" clearable>
                <el-option label="好评" :value="1"></el-option>
                <el-option label="中评" :value="2"></el-option>
                <el-option label="差评" :value="3"></el-option>
            </el-select>
            <el-date-picker class="filter-item" v-model="listQuery.start_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
            <el-date-picker class="filter-item" v-model="listQuery.end_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column type="expand" min-width="40">
                <template slot-scope="props">
                    <div style="margin-left: 10%">
                        <el-form label-position="left" inline class="custom-inline-form">
                            <el-row>评价信息</el-row>
                            <el-row>
                                <el-form-item label="评价内容:">
                                    <span>{{ props.row.content }}</span>
                                </el-form-item>
                            </el-row>

                            <el-row>
                                <el-form-item label="评价图片:">
                                    <viewer :images="props.row.imgs">
                                        <img v-for="(src,index) in props.row.imgs" :src="src" :key="index" style="margin-right: 10px; width: 100px; height: 100px; object-fit: contain; border: 1px solid #ccc; border-radius: 5px; cursor: pointer;">
                                    </viewer>
                                </el-form-item>
                            </el-row>

                            <el-row>
                                <el-form-item label="对骑手评论:">
                                    <span>{{ props.row.sendman_content }}</span>
                                </el-form-item>
                            </el-row>
                        </el-form>

                        <el-form label-position="left" inline class="custom-inline-form" v-if="props.row.add_comment">
                            <el-row>追评信息</el-row>
                            <el-row>
                                <el-form-item label="追评内容:">
                                    <span>{{ props.row.add_comment.content }}</span>
                                </el-form-item>
                            </el-row>

                            <el-row>
                                <el-form-item label="追评时间:">
                                    <span>{{ props.row.add_comment.created_at }}</span>
                                </el-form-item>
                            </el-row>
                        </el-form>

                        <el-form label-position="left" inline class="custom-inline-form" v-if="props.row.reply_comment">
                            <el-row>商家回复信息</el-row>
                            <el-row>
                                <el-form-item label="回复内容:">
                                    <span>{{ props.row.reply_comment.content }}</span>
                                </el-form-item>
                            </el-row>

                            <el-row>
                                <el-form-item label="回复时间:">
                                    <span>{{ props.row.reply_comment.created_at }}</span>
                                </el-form-item>
                            </el-row>
                        </el-form>
                    </div>
                </template>
            </el-table-column>

            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    <!-- {{ scope.$index }} -->
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="订单号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order.order_no }}
                </template>
            </el-table-column>

            <el-table-column label="店铺" min-width="110" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.shop.shop_name }}</div>
                </template>
            </el-table-column>

            <el-table-column label="用户" min-width="110" align="center">
                <template slot-scope="scope">
                    <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
                        <img :src="scope.row.user.avatar" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
                        <span>{{ scope.row.user.nickname }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="是否匿名" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.is_anonymous === 0" type="success">否</el-tag>
                    <el-tag v-else-if="scope.row.is_anonymous === 1" type="danger">是</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="口味评分" min-width="80" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.goods_score }}</div>
                </template>
            </el-table-column>

            <el-table-column label="包装评分" min-width="80" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.pack_score }}</div>
                </template>
            </el-table-column>

            <el-table-column label="商家态度评分" min-width="80" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.shop_score }}</div>
                </template>
            </el-table-column>

            <el-table-column label="对商家总体评分" min-width="80" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.shop_total_score }}</div>
                </template>
            </el-table-column>

            <el-table-column label="配送速度评分" min-width="80" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.speed_score }}</div>
                </template>
            </el-table-column>

            <el-table-column label="骑手态度评分" min-width="80" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.sendman_score }}</div>
                </template>
            </el-table-column>

            <el-table-column label="评价时间" min-width="120" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.created_at }}</div>
                </template>
            </el-table-column>

          <el-table-column label="操作" width="150" align="center" fixed="right" class-name="small-padding fixed-width" v-if="user.user.username === 'admin'">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                shops: [],
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    order_no: '',
                    shop_id: '',
                    satisfied_status: '',
                    start_time: '',
                    end_time: '',
                },
            };
        },
        created() {
            this.getList();
            this.getShopList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
                this.getShopList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/comment/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            getShopList() {
                request({
                    url: "/api/backend/shop/list",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 1000000,
                        school_id: this.school_id,
                        canteen_id: this.listQuery.canteen_id,
                    }
                }).then(response => {
                    this.shops = response.data.data;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            handleDelete(row) {
                this.$confirm("确认继续该操作", "提示", {
                    type: "warning"
                }).then(() => {
                    request({
                        url: "/api/backend/comment/delete",
                        method: "post",
                        data: {
                            id: row.id
                        }
                    }).then(()=>{
                        this.getList();
                        this.list.length <= 1 && this.listQuery.page > 1 ? this.listQuery.page-- : false;
                        this.$message({
                            type: "success",
                            message: "操作成功"
                        });
                    })
                }).catch(() => {});
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss">
    .custom-inline-form {
        margin-bottom: 10px;
        .el-row {
            padding: 4px 0;
            font-weight: bold;
        }
        .el-form-item {
            margin-bottom: 4px !important;
        }
        .el-form-item__label {
            line-height: 20px !important;
        }
        .el-form-item__content {
            line-height: 20px !important;
        }
    }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
